import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="auto-hide"
export default class extends Controller {
  static targets = ['hideable']

  connect () {
    // Hide the hidable elements by setting opacity to 0
    this.hideableTargets.forEach(element => {
      element.style.opacity = 0
      // Add a class of "slow-fade" to the element
      element.classList.add('slow-fade')
    })
  }

  toggle () {
    // Toggle the opacity of the hidable elements
    this.hideableTargets.forEach(element => {
      element.style.opacity = element.style.opacity === '0' ? '1' : '0'
    })
  }
}
