// Lucide Icon Controller
// This controller is responsible for rendering Lucide icons in the DOM.

import { Controller } from '@hotwired/stimulus'
import { createElement, Eye } from 'lucide';

export default class extends Controller {
  connect () {
    const iconName = this.element.dataset['lucide']

    const iconElement = createElement(iconsMap[iconName], {
      height: 16,
    })
    this.element.appendChild(iconElement)
  }
}

const iconsMap = {
  "eye": Eye
}
